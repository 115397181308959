import useProducts from '@/compositions/products';
import { pickBy } from 'lodash';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'TakeAwayProductsForm',
    props: {
        takeaway: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: false,
            default: 0
        }
    },
    setup: () => ({
        ...useProducts()
    }),
    data: () => ({
        takeawayProducts: [],
        collapsed: {}
    }),
    computed: {
        productByGroup() {
            return this.$store.direct.getters.products.productByGroup;
        },
        productGroups() {
            return this.$store.direct.state.products.productGroups;
        },
        productsByGroupsInTimeslot() {
            const hour = this.takeaway.pickUpDate.getHours();
            return pickBy(this.productByGroup, (_, groupId) => { var _a; return (_a = this.productGroups[groupId]) === null || _a === void 0 ? void 0 : _a.withinTimeslot(hour); });
        },
        productCount() {
            const count = {};
            this.takeawayProducts.forEach(product => {
                const id = product.id;
                if (count[id] === undefined)
                    count[id] = 0;
                count[id]++;
            });
            return count;
        }
    },
    mounted() {
        this.takeaway.products.forEach(product => {
            const prod = pickBy(this.products, stockProduct => product.name === stockProduct.name);
            const id = Object.keys(prod)[0];
            const foundProduct = Object.values(prod)[0];
            if (id !== undefined && foundProduct !== undefined)
                this.takeawayProducts.push({ id, product: foundProduct });
        });
    },
    methods: {
        addProduct(productId) {
            const product = this.products[productId];
            if (product === undefined) {
                console.error(`Product with Id ${productId} cannot be added, does not exists`);
                return;
            }
            this.takeawayProducts.push({ id: productId, product });
            if (this.takeaway !== undefined) {
                this.takeaway.products = this.takeawayProducts.map(product => product.product);
            }
        },
        removeProduct(productId) {
            const index = this.takeawayProducts.findIndex(product => product.id === productId);
            if (index === -1) {
                console.error(`Cannot remove product with id ${productId}, was never chosen`);
                return;
            }
            this.takeawayProducts.splice(index, 1);
            if (this.takeaway !== undefined) {
                this.takeaway.products = this.takeawayProducts.map(product => product.product);
            }
        },
        collapseToggle(groupId) {
            this.collapsed[groupId] = !this.collapsed[groupId];
        }
    }
});
