import store from '@/store';
import { onBeforeUnmount, onMounted, computed } from 'vue';
import { countDaysBetween } from '@/classes/timestamps';
const useReservations = (setDefaultFilter = false) => {
    onMounted(async () => {
        await store.dispatch.reservations.subscribe();
        if (setDefaultFilter) {
            const now = new Date();
            const thisMorning = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            const in2weeks = new Date(thisMorning.getTime() + 14 * 24 * 3600 * 1000);
            await store.dispatch.reservations.applyFilter({
                field: null,
                value: '',
                fromDate: thisMorning,
                toDate: in2weeks,
                sorted: 'fromDate'
            });
        }
    });
    onBeforeUnmount(async () => {
        await store.dispatch.reservations.unsubscribe();
    });
    const reservations = computed(() => {
        return store.state.reservations.reservations;
    });
    const minReservationDate = computed(() => {
        var _a;
        return (_a = store.state.reservations.filter.fromDate) !== null && _a !== void 0 ? _a : new Date();
    });
    const maxReservationDate = computed(() => {
        var _a;
        return (_a = store.state.reservations.filter.toDate) !== null && _a !== void 0 ? _a : new Date();
    });
    const reservationDaySpan = computed(() => {
        return countDaysBetween(minReservationDate.value, maxReservationDate.value);
    });
    return {
        reservations,
        minReservationDate,
        maxReservationDate,
        reservationDaySpan
    };
};
export default useReservations;
