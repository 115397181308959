import store from '@/store';
import { onBeforeUnmount, onMounted, computed } from 'vue';
const useDaySummaries = () => {
    onMounted(async () => {
        await store.dispatch.daysummaries.subscribe();
    });
    onBeforeUnmount(async () => {
        await store.dispatch.daysummaries.unsubscribe();
    });
    const daySummary = computed(() => {
        return store.state.daysummaries.daySummary;
    });
    const daySummaryFilter = computed(() => {
        return store.state.daysummaries.filter;
    });
    const multiDaySummary = computed(() => {
        return store.getters.daysummaries.multidaySummary;
    });
    return {
        daySummary,
        daySummaryFilter,
        multiDaySummary
    };
};
export default useDaySummaries;
