import store from '@/store';
import { onBeforeUnmount, onMounted, computed } from 'vue';
const useProducts = () => {
    onMounted(async () => {
        await store.dispatch.products.subscribe();
    });
    onBeforeUnmount(async () => {
        await store.dispatch.products.unsubscribe();
    });
    const products = computed(() => {
        return store.state.products.products;
    });
    return {
        products
    };
};
export default useProducts;
