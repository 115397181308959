import store from '@/store';
import { onBeforeUnmount, onMounted, computed } from 'vue';
const useTakeAway = (setDefaultFilter = false) => {
    onMounted(async () => {
        await store.dispatch.takeaway.subscribe();
        if (setDefaultFilter) {
            const now = new Date();
            const thisMorning = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            const in2weeks = new Date(thisMorning.getTime() + 14 * 24 * 3600 * 1000);
            await store.dispatch.takeaway.applyFilter({
                field: null,
                value: '',
                fromDate: thisMorning,
                toDate: in2weeks
            });
        }
    });
    onBeforeUnmount(async () => {
        await store.dispatch.takeaway.unsubscribe();
    });
    const takeaways = computed(() => {
        return store.state.takeaway.takeaway;
    });
    return {
        takeaways
    };
};
export default useTakeAway;
