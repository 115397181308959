import store from '@/store';
import { onBeforeUnmount, onMounted, computed } from 'vue';
const useLocations = () => {
    onMounted(async () => {
        await store.dispatch.locations.subscribe();
    });
    onBeforeUnmount(async () => {
        await store.dispatch.locations.unsubscribe();
    });
    const currentLocation = computed(() => {
        return store.getters.locations.currentLocation;
    });
    const currentLocationId = computed(() => {
        return store.state.locations.currentLocationId;
    });
    return {
        currentLocation,
        currentLocationId
    };
};
export default useLocations;
