import { defineComponent } from 'vue';
import Datepicker from 'vue3-datepicker';
import { nl } from 'date-fns/locale';
import useAuth from '@/compositions/auth';
import { dateToTimeOfDay, convertTimestamp, isBreakfastTime } from '@/classes/timestamps';
import useDaySummaries from '@/compositions/daysummaries';
import useVuelidate from '@vuelidate/core';
import dateWithinReservation from '@/validators/dateWithinReservation';
import { required } from '@vuelidate/validators';
import TakeAwayProductsForm from '../TakeAwayProductsForm';
export default defineComponent({
    name: 'TakeAwayForm',
    components: {
        Datepicker,
        TakeAwayProductsForm
    },
    props: {
        takeaway: {
            type: Object,
            required: true
        },
        reservation: {
            type: Object,
            required: true
        },
        editMode: {
            type: Boolean,
            required: false,
            default: false
        },
        isNew: {
            type: Boolean,
            required: false,
            default: false
        },
        index: {
            type: Number,
            required: false,
            default: 0
        }
    },
    emits: {
        isValid(_valid) { return true; }
    },
    setup(props) {
        const rules = {
            pickUpDate: { correctDate: props.editMode ? dateWithinReservation(props.reservation) : required },
            products: { required }
        };
        return {
            validate: useVuelidate(rules, props.takeaway), ...useAuth(), ...useDaySummaries()
        };
    },
    data: () => ({
        locale: nl,
        showDinner: false,
        showBreakfast: false
    }),
    computed: {
        canAdjustDate() {
            if (!this.editMode)
                return true;
            if (this.isSuperUser)
                return true;
            return false;
        },
        pickUpTime: {
            get() {
                return this.takeaway.pickUpDate.getTime();
            },
            set(time) {
                this.takeaway.pickUpDate = new Date(time);
            }
        },
        availablePickupDates() {
            const pickupDate = this.takeaway.pickUpDate;
            let spots = [];
            if (this.showDinner)
                spots = this.$store.direct.getters.daysummaries.multidaySummary.getOpenTakeawaySpots(pickupDate, this.isSuperUser);
            else
                spots = this.$store.direct.getters.daysummaries.multidaySummary.getBreakfastTiming(pickupDate);
            if (!this.isNew) {
                spots.push(this.takeaway.pickUpDate.getTime());
            }
            return spots.map(spot => ({
                value: spot,
                text: dateToTimeOfDay(new Date(spot))
            }));
        },
        dateTitle() {
            return this.takeaway.products.length > 0 ? convertTimestamp(this.takeaway.pickUpDate.getTime(), false)
                : 'nog te kiezen';
        },
        hasProducts() {
            return this.takeaway.products.length > 0;
        }
    },
    watch: {
        availablePickupDates() {
            if (!this.availablePickupDates.some(date => date.value === this.pickUpTime)) {
                const firstDate = this.availablePickupDates[0];
                if (firstDate !== undefined)
                    this.pickUpTime = firstDate.value;
            }
        },
        reservation: {
            handler() {
                this.validate.$touch();
                this.validate.pickUpDate.$reset();
                const pickupDate = this.takeaway.pickUpDate.getTime();
                if (pickupDate < this.reservation.fromDate.getTime()) {
                    this.takeaway.pickUpDate = this.reservation.fromDate;
                }
                else if (pickupDate > this.reservation.toDate.getTime()) {
                    this.takeaway.pickUpDate = this.reservation.toDate;
                }
            },
            deep: true,
            immediate: true
        },
        'validate.$invalid'(error) {
            this.$emit('isValid', !error);
        }
    },
    mounted() {
        if (this.takeaway.products.length > 0) {
            isBreakfastTime(this.takeaway.pickUpDate) ? this.showBreakfast = true : this.showDinner = true;
        }
    },
    methods: {
        setShowDinner(value) {
            this.showDinner = value;
            this.showBreakfast = false;
        },
        setShowBreakfast(value) {
            const breakfastTime = this.$store.direct.getters
                .daysummaries.multidaySummary.getBreakfastTiming(this.takeaway.pickUpDate)[0];
            if (breakfastTime === undefined)
                return;
            this.pickUpTime = breakfastTime;
            this.showBreakfast = value;
            this.showDinner = false;
        }
    }
});
